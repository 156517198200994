<template>
  <div class="sidebar bg_darkest">
    <div class="row m-0 h-100">
      <div class="col-12 p-0 overflow_y h-100 px-5 pt-5 pb-4">
        <div class="row m-0 h-100 align-items-start">
          <div class="col-12 p-0">
            <div class="row m-0">
              <profile-image  class="abs_img"/>
              <div class="col-12 mb-5 text-center font2p color_beige" v-if="profile">
                {{profile.namePreferred || profile.name}}
              </div>
              <div class="col-12">
                <custom-menu :selectedMenuItem="selectedMenuItem" @selectMenu="selectMenu"></custom-menu>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end">
            <div class="row m-0">
              <div class="col-12 mb-3 font5 bold color_beige clickable" @click="selectMenu('socialMedia')">
                SOCIAL LINKS
              </div>
            </div>
            <div class="row m-0" v-if="false">
              <div class="col-12 mb-3 font5 bold color_beige clickable">
                <fb-icon class="mr-2"></fb-icon>
                <ig-icon class="mr-2"></ig-icon>
                <yt-icon></yt-icon>
              </div>
            </div>
            <div class="row m-0 mt-4">
              <div class="col-6 font5 regular color_white clickable" @click="logOut()">
                LOG OUT
              </div>
              <div class="col-6 font5 regular color_white text-right clickable" @click="openPP()">
                [Privacy Policy]
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="submenu" v-if="showSubMenu">
      <div class="row m-0 h-100">
        <div class="col-12 p-0 overflow_y h-100">
          <div class="row m-0 h-100 align-items-center">
            <div class="col-12">
              <custom-submenu :parentMenuValue="selectedMenuItem" @activeSubMenu="activeSubMenu"></custom-submenu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <t-and-c v-if="showModalTAndC" @close="showModalTAndC=false"></t-and-c>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
const fbIcon = () => import('@/icons/Facebook')
const igIcon = () => import('@/icons/Instagram')
const ytIcon = () => import('@/icons/YouTube')

const TAndC = () => import('../components/modals/TAndC')
export default {
  name: 'Sidebar',
  props: ['user', 'theme', 'selectedMenuItem', 'showSubMenu'],
  components: {
    fbIcon,
    igIcon,
    ytIcon,
    TAndC,
    'custom-menu': () => import('@/components/Menu.vue'),
    'custom-submenu': () => import('@/components/Submenu.vue'),
    'profile-image': () => import('@/components/ProfileImage.vue')
  },
  data () {
    return {
      showModalTAndC: false
    }
  },
  computed: {
    ...mapGetters([
      'profile'
    ])
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    async logOut () {
      try {
        await this.logout()
      } catch (error) {
        console.error('SideBar', error)
      }
      this.$router.push('/login')
    },
    activeSubMenu (val) {
      this.$emit('activeSubMenu', val)
    },
    selectMenu (item) {
      this.$emit('selectMenu', item)
    },
    openPP () {
      this.showModalTAndC = true
    }
  }
}
</script>
<style scoped>
@media (min-width: 992px) {
  .sidebar {
    height: 100vh;
    width: 30vw;
    position: absolute;
    /* overflow-y: auto; */
  }
  .submenu {
    height: 100vh;
    width: 20vw;
    position: absolute;
    right: -20vw;
    top: 0;
    background-color: var(--beige085);
    color: var(--white);
    font-size: 18px;
    line-height: 45px;
    letter-spacing: 0.46px;
    font-weight: 300;
    z-index: 9;
  }
  .socialmedia[data-v-3eca7188] {
    width: 80vw;
    right: -80vw;
  }
  .font2 {
    font-size: 30px;
    line-height: 37px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  .maincontent {
    height: 100vh;
    width: 70vw;
    background-color: #F5F5F5;
    position: absolute;
    right: 0;
  }
}
</style>
